import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch, withStyles } from '@material-ui/core'
import TaTypography from '../typography/TaTypography'
import { Controller } from 'react-hook-form'
import uuid from 'react-uuid'

const TaSwitch = withStyles({
  switchBase: {
    color: 'var(--main-bg-color2)',
    '&$checked': {
      color: 'var(--main-text-color2)',
    },
    '&$checked + $track': {
      backgroundColor: 'var(--main-bg-color1)',
    },
  },

  checked: {},
  track: {
    backgroundColor: 'var(--main-bg-color1)'
  },
})(Switch);

const TaSwitchV3 = memo(({ name, control, status, disabled, label, id }) => {
  /* 
  CREADA POR Reinaldo Zambrano
  Componente Switch reutilizable para React hook forms
  */
  return (
    <Controller
      id={id}
      name={name}
      control={control}
      defaultValue={status}
      render={({ onChange, value, name }) => (
        <FormControlLabel
          control={
            <TaSwitch
              id={`${id}:${uuid()}`}
              name={name}
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
              disabled={disabled && disabled} />
          }
          label={<TaTypography color={'var(--main-title-color0)'} >{label}</TaTypography>} />
      )}
    />
  )
})

TaSwitchV3.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default TaSwitchV3