import React, { memo, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
import {
  Grid,
  Paper,
  List,
} from '@material-ui/core'
import TaTypography from 'components/typography/TaTypography'
import TaButton from '../../components/button/TaButton'
import TaTextField from '../../components/textfield/TaTextFieldV4'
import NumberTextField from '../../components/textfield/NumberTextField'
import BankDetail from "./BankDetail"
import { SnackbarContext } from 'components/snackbar/SnackbarContext'
import { getExchangeFactor } from 'services/API/exchangeFactor/'
import TaListTextField from 'components/textfield/TaListTextField';
import { useForm } from "react-hook-form"

const banksList = [
  { id: "Banesco", name: "Banesco" },
  { id: "Provincial", name: "Provincial" },
  { id: "BanescoPanama", name: "Banesco Panama" }]

const transferModel = {
  bank: "",
  date: "",
}

const Transfer = memo(({ banks, addBank, delBank, setPayAmount, setIsLoading, appCost }) => {

  const [maxPayAmount, setMaxPayAmount] = useState(0);
  const [exchangeFactor, setExchangeFactor] = useState(0);
  const [remainingPayAmount, setRemainingPayAmount] = useState(0)
  const { sbDispatch } = useContext(SnackbarContext);

  const fnGetFactor = async () => {
    try {
      setIsLoading(true)
      const response = await getExchangeFactor()
      setIsLoading(false)
      if (response.status === 200) {
        let newValue = +((response.data.factor * appCost).toFixed(2))
        setMaxPayAmount(newValue)
        setPayAmount(newValue)
        setExchangeFactor(response.data.factor)
      } else {
        sbDispatch.error("Error obteniendo tasa de cambio")
      }
    } catch (error) {
      setIsLoading(false)
      sbDispatch.error("Error no controlado obteniendo tasa de cambio")
      console.error('fnGetFactor => ', error)
    }
  }

  useEffect(() => {
    fnGetFactor();
  }, []);

  useEffect(() => {
    let newValue = +((exchangeFactor * appCost).toFixed(2))
    setMaxPayAmount(newValue)
    setPayAmount(newValue)
  }, [appCost])

  const { handleSubmit, register, errors, setValue, reset, watch, control } = useForm({
    defaultValues: transferModel,
  });

  const fnOnSubmit = async (data) => {
    if (exchangeFactor !== 0) {
      addBank({ ...data, factor: exchangeFactor });
      setValue("date", "")
      setValue("amount", "")
      setValue("refere", "")
      setValue("fiscalID", "")
    } else {
      sbDispatch.error("No se logro obtener el factor de cambio, cierre el registro y reintente nuevamente")
    }

  }

  const watchAmount = watch('amount')
  useEffect(() => {
    if (watchAmount) {
      if (watchAmount >= remainingPayAmount) {
        setValue('amount', +(remainingPayAmount.toFixed(2)))
      }
    }
  }, [watchAmount])

  useEffect(() => setRemainingPayAmount(maxPayAmount - banks.reduce((val, x) => (val + x.amount), 0)), [maxPayAmount, banks])

  return (
    <form key="transfer" onSubmit={handleSubmit(fnOnSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
          <TaListTextField
            name={'bank'}
            label={'Seleccione un banco'}
            register={register}
            required={true}
            setValue={setValue}
            errors={errors}
            options={banksList}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={7} xl={7}>
          <TaTypography text={`Bs: ${maxPayAmount.formatNumb(2)} (Tasa : ${exchangeFactor.formatNumb(2)})`} fontSize="14pt" color="var(--main-text-color0)" align="right" />
          <TaTypography fontSize="10pt" color="var(--main-text-color0)" align="right">
            Para ver cuentas donde realizar el pago click  <a target="_blank" href="https://totalcoin.totalaplicaciones.com:8086/documentos/cuentas_ta.pdf"> aqui</a>
          </TaTypography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TaTextField
            name="date"
            label="Fecha transacción *"
            register={register({ required: "Requerido" })}
            errors={errors}
            type="date"
            fullWidth={true} />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
          <NumberTextField
            register={register({ required: "Requerido", })}
            setValue={setValue}
            name="amount"
            label="Monto *"
            errors={errors} />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
          <TaTextField
            name="refere"
            label="Referencia *"
            register={register({ required: "Requerido" })}
            errors={errors}
            fullWidth={true} />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
          <TaTextField
            name="fiscalID"
            label="ID Fiscal *"
            register={register({ required: "Requerido" })}
            errors={errors}
            fullWidth={true} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TaButton label='Agregar' type="submit" fontSize="10pt"></TaButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={banks.length === 0}>
          <Paper elevation={0} style={{ height: "175px", overflowY: "auto", overflowX: "hidden", backgroundColor: "var(--main-bg-color1)" }}>
            <List component="nav">
              {
                banks.map(bank => <BankDetail key={bank.id} data={bank} delBank={delBank} />)
              }
            </List>
          </Paper>
        </Grid>
      </Grid>
    </form>

  );
});

Transfer.propTypes = {

};

export default Transfer;