import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TaButton from '../../../../../components/button/TaButton';
import TaTypography from '../../../../../components/typography/TaTypography';

const Menu = memo(({ title, fnFirstButton, secondTextButton, secondLinkButton, thirdLinkButton, fourthLinkButton }) => {
    return (
        <Grid container alignContent="center" alignItems="center" spacing={1} style={{ backgroundColor: "#B3B3B3", height: "380px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaTypography text={title} fontSize="18pt" fontFamily="GothamMedium" color="white" align='center' />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='center'>
                <TaButton label={'Comprar'} onClick={fnFirstButton}></TaButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='center'>
                <TaButton label={secondTextButton} href={secondLinkButton}></TaButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='center'>
                <TaButton label='Manual' href={thirdLinkButton} target="_blank"> </TaButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='center' hidden={fourthLinkButton == undefined}>
                <TaButton label='Guia visual' href={fourthLinkButton} target="_blank">  </TaButton>
            </Grid>
        </Grid>
    );
});

Menu.propTypes = {
    title: PropTypes.string.isRequired
};

export default Menu;