import { Grid } from '@material-ui/core'
import TaDialog from 'components/dialog/TaDialog'
import React, { memo } from 'react'
import TaButton from '../../components/button/TaButton'
import TaTypography from '../../components/typography/TaTypography'

const ModalConfirmation = memo(({fnAcceptBussinessPartner,businessPartnerInfo }) => {
  return (
    <TaDialog
      id={'ModalConfirmation'}
      isOpen={true}
      fullWidth={true}
      maxWidth={'md'}
      title={'Confirmación'}
      content={
        <Grid container alignContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaTypography fontSize={'14pt'} color={'var(--main-title-color0)'} text={`Actualmente usted recibe soporte por parte del aliado comercial: `} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaTypography fontSize={'14pt'} color={'var(--main-title-color0)'} text={`Nombre: ${businessPartnerInfo.name}`} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaTypography fontSize={'14pt'} color={'var(--main-title-color0)'} text={`Teléfono: ${businessPartnerInfo.phone}`} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaTypography fontSize={'14pt'} color={'var(--main-title-color0)'} text={`Correo: ${businessPartnerInfo.email}`} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaTypography fontSize={'14pt'} color={'var(--main-title-color0)'} text={`Si desea realizar la reconversión de manera desasistida, por favor clic en continuar`} />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container alignContent="flex-start" alignItems="flex-start" >
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
            <TaButton
              id={'btnContact'}
              label={'Contactar Aliado comercial'}
              onClick={fnAcceptBussinessPartner}
              fontSize={'12pt'}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
            <TaButton
              id={'btnContinue'}
              label={'Continuar'}
              onClick={fnAcceptBussinessPartner}
              fontSize={'12pt'}
            />
          </Grid>
        </Grid>
      }
      elevation={2}
      isTransparent={false}
    />
  )
})

export default ModalConfirmation
