import { urlRm } from 'services/global'
import {
    fnGetUrl, 
    fnPostUrl,
} from "functions/Api";

export const getZelleCode = () => {
    const apiRequest = {
        endpoint: `api/Zelle/`,
        baseUrl: urlRm
    }
    return fnGetUrl(apiRequest)
}

export const postZelle = (code, amount) => {
    try {
        const body = { cod: code, amount: amount }
        const apiRequest = {
            endpoint: 'api/Zelle',
            baseUrl: urlRm,
            body: body
        }
        return fnPostUrl(apiRequest)
    } catch (error) {
        console.error('postRegister =>', error)
    }
}
