import Grid from '@material-ui/core/Grid';
import logoTa from '../../../../img/logoisonegativo.png';
import icoTelefono from '../../../../img/iconos/Telefono.png';
import icoWhatsApp from '../../../../img/iconos/WhatsApp.png';
import icoEmail from '../../../../img/iconos/CorreoElectronico.png';
import "./index.css"
import TaTypography from '../../../../components/typography/TaTypography';
import icoIG from '../../../../img/iconos/Instagram.png';
import icoFB from '../../../../img/iconos/Facebook.png';
import icoTW from '../../../../img/iconos/Twitter.png';
import icoYT from '../../../../img/iconos/Youtube.png';
const Footer = () => {
    return (
        <Grid container alignContent="center" spacing={1} className="footer">
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align="center">
                <figure>
                    <a href="http://www.totalaplicaciones.com/" target="_blank"><img src={logoTa} alt="TotalAplicaciones" className="imgTaFooter" /></a >
                </figure>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align="center">
                <Grid container alignContent="flex-start" spacing={3} alignItems="flex-start">
                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="center">
                        <img src={icoTelefono} alt="Teléfono" className="icoFooter" />
                    </Grid>
                    <Grid item xs={9} sm={9} md={10} lg={10} xl={10} align="center">
                        <Grid container alignContent="center" spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <TaTypography text="Teléfonos" fontSize="14pt" fontFamily="GothamMedium" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} align="center">
                                <TaTypography text="+58 (212) 614 50 14" fontSize="14pt" />
                                <TaTypography text="+58 (212) 618 06 44" fontSize="14pt" />
                                <TaTypography text="+58 (212) 618 06 66" fontSize="14pt" />
                                <TaTypography text="+58 (212) 618 02 19" fontSize="14pt" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} align="center">
                                <TaTypography text="+58 (212) 424 96 27" fontSize="14pt" />
                                <TaTypography text="+58 (212) 735 10 50" fontSize="14pt" />
                                <TaTypography text="+58 (414) 624 96 77" fontSize="14pt" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="center">
                        <img src={icoWhatsApp} alt="WhatsAppp" className="icoFooter" />
                    </Grid>
                    <Grid item xs={9} sm={9} md={10} lg={10} xl={10} align="center">
                        <Grid container alignContent="center" spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <TaTypography text="WhatsApp" fontSize="14pt" fontFamily="GothamMedium" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} align="center">
                                <TaTypography text="+58 (414) 927 24 43" fontSize="14pt" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} align="center">
                                <TaTypography text="+58 (414) 380 67 66" fontSize="14pt" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="center">
                        <img src={icoEmail} alt="Correo Electrónico" className="icoFooter" />
                    </Grid>
                    <Grid item xs={9} sm={9} md={10} lg={10} xl={10} align="center">
                        <Grid container alignContent="center" spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <TaTypography text="Correo Electrónico" fontSize="14pt" fontFamily="GothamMedium" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <TaTypography text="info@totalaplicaciones.com"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <a href="https://instagram.com/totalaplicaciones?utm_medium=copy_link" target="_blank">
                    <img className="icoSocialMedia" src={icoIG} alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/totalaplicaciones" target="_blank">
                    <img className="icoSocialMedia" src={icoFB} alt="Facebook" />
                </a>
                <a href="https://twitter.com/totalaplicacion" target="_blank">
                    <img className="icoSocialMedia" src={icoTW} alt="Twitter" />
                </a>
                <a href="https://www.youtube.com/totalaplicaciones" target="_blank">
                    <img className="icoSocialMedia" src={icoYT} alt="Youtube" />
                </a>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaTypography text="Copyright © 2021 Reconversión Total Aplicaciones" fontSize="16pt" align="center"/>
            </Grid>
        </Grid>
    );
};

export default Footer;