export const initialValuesLic = {
    license: "",
    licenseType: "",
    fiscalID: "",
    fiscalName: "",
    email: "",
    phone: "",
    isPersBill: false,
    isAgentRet: false,
}

export const initialValuesTc = {
    email: "",
    pass: "",
    balance: 0,
    price: 0,
    remaining: 0,
    codAliComer: "",
}

export const initialInfoAliComerModel = { 
    name: null, 
    phone: null, 
    email: null 
}

export const initialIPaymentMethod = { 
    paymentMethod: '', 
}