import {urlRm} from 'services/global'
import {
    fnGetUrl,
} from "functions/Api";

export const getExchangeFactor = () => {
    const apiRequest = {
        endpoint: `api/Factor/`,
        baseUrl: urlRm
    }
    return fnGetUrl(apiRequest)
}