import {urlTc, urlRm} from 'services/global'
import {
    fnGetUrl, fnPostUrl,
} from "functions/Api";

export const getLicense = (license) => {
    const apiRequest = {
        endpoint: `api/Licencia/PlataformaSaint/${license}`,
        baseUrl: urlTc
    }
    return fnGetUrl(apiRequest)
}

export const PostLicenseAcc = (license) => {
    const apiRequest = {
        endpoint: `api/Saec/`,
        baseUrl: urlRm,
        body: {
            license: license
        }
    }
    return fnPostUrl(apiRequest)
}