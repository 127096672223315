import { memo } from "react";
import {
    Grid,
} from '@material-ui/core'
import {
    regexEmail,
} from "helper/RegExp";
import TaTextField from '../../components/textfield/TaTextFieldV4'
import TaButton from '../../components/button/TaButton';

const Totalcoin = memo(({ registerTc, errorsTc, handleSubmitTc, fnOnSubmitTc }) => {
    return (
        <form key="tc" onSubmit={handleSubmitTc(fnOnSubmitTc)}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TaTextField
                        name="email"
                        label="Usuario Totalcoin (Correo electrónico) *"
                        register={registerTc({ required: "Requerido", pattern: regexEmail })}
                        errors={errorsTc}
                        fullWidth={true} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TaTextField
                        name="pass"
                        label="Contraseña *"
                        register={registerTc({ required: "Requerido" })}
                        errors={errorsTc}
                        fullWidth={true}
                        type="password" />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TaButton label='Validar' type="submit" fontSize="12pt"></TaButton>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TaTextField
                        name="codAliComer"
                        label="Código aliado comercial"
                        register={registerTc}
                        errors={errorsTc}
                        fullWidth={true}
                        disabled={true} />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TaTextField
                        name="balance"
                        label="Saldo"
                        register={registerTc}
                        errors={errorsTc}
                        fullWidth={true}
                        disabled={true} />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TaTextField
                        name="price"
                        label="Cantidad TC"
                        register={registerTc}
                        errors={errorsTc}
                        fullWidth={true}
                        disabled={true} />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TaTextField
                        name="remaining"
                        label="Saldo final"
                        register={registerTc}
                        errors={errorsTc}
                        fullWidth={true}
                        disabled={true} />
                </Grid>
            </Grid>
        </form>

    );
});

export default Totalcoin;