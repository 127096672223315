import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import useTextField from "helper/UseTextField";
const onFocus = (e) => {
    e.target.select()
}
//
const TaTextField = memo(({ name, label, register, fullWidth, errors, type, disabled, autoFocus, onBlur, placeholder = "" }) => {
    const txt = useTextField(name, label, errors, type, placeholder, undefined, "");

    const fnOnBlur = useCallback((event) => {
        if (onBlur) {
            onBlur(event)
        }
    }, [onBlur]);

    return (
        <TextField
            {...txt}
            fullWidth={fullWidth}
            inputRef={register}
            disabled={disabled}
            autoFocus={autoFocus}
            autoComplete={type === 'password' ? 'new-password' : 'off'}
            onBlur={fnOnBlur}
            onFocus={onFocus} />
    );
});

TaTextField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    register: PropTypes.func,
    errors: PropTypes.object,
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

TaTextField.defaultProps = {
    type: 'text',
    fullWidth: false,
    disabled: false,
    autoFocus: false,
    placeholder: "",
}

export default TaTextField;