
import { valueApp } from "./RegisterConstants";

export const fnCalculateIvaForRetAgent = (agentRet) => {
    try {
        if (agentRet === true) {
            let base = (valueApp / 1.16);
            let tax = (valueApp - base)
            return (base + (tax - (tax * 0.75))).toFixed(2)
        }
        return valueApp
    } catch (error) {
        console.error('fnCalculateIvaForRetAgent =>', error)
    }
}