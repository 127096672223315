import Grid from '@material-ui/core/Grid';
import Header from './components/header/';
import Body from './components/body/';
import Footer from './components/footer/';

const Home = () => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Header />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Body />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default Home;