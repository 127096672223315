import { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core'
import {
    regexLicense,
} from "helper/RegExp";
import TaTextField from '../../components/textfield/TaTextFieldV4'
import TaButton from '../../components/button/TaButton';
import TaSwitchV4 from '../../components/switch/TaSwitchV4';
import TaTypography from 'components/typography/TaTypography';
import TaSwitchV3 from 'components/switch/TaSwitchV3';

const InfoLicense = memo(({ registerLic, errorsLic, handleSubmitLic, fnOnSubmitLic,
    isDisabledField, isInfoLic, isPersBill, control }) => {
    return (
        <form key="lic" onSubmit={handleSubmitLic(fnOnSubmitLic)}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        name="license"
                        label="Licencia Saint Enterprise Administrativo *"
                        placeholder="ABDC123456789012345"
                        register={registerLic({ required: "Requerido", pattern: regexLicense })}
                        errors={errorsLic}
                        fullWidth={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaButton label='Verificar' type="submit" fontSize="10pt"></TaButton>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        name="licenseType"
                        label="Tipo de licencia"
                        register={registerLic}
                        errors={errorsLic}
                        fullWidth={true}
                        disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        name="fiscalID"
                        label="Identificador Fiscal"
                        register={registerLic}
                        errors={errorsLic}
                        fullWidth={true}
                        disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TaTextField
                        name="fiscalName"
                        label="Razón Social"
                        register={registerLic}
                        errors={errorsLic}
                        fullWidth={true}
                        disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        name="email"
                        label="Correo electrónico"
                        register={registerLic}
                        errors={errorsLic}
                        fullWidth={true}
                        disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        name="phone"
                        label="Teléfono"
                        register={registerLic}
                        errors={errorsLic}
                        fullWidth={true}
                        disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaSwitchV3
                        id={'switchIsPersBill'}
                        name="isPersBill"
                        status={false}
                        disabled={!isInfoLic}
                        label={"Factura personalizada"}
                        control={control}
                        register={registerLic} />
                    <TaTypography text="* Se enviará a su correo electrónico una plantilla con la información requerida" color="var(--main-text-color0)" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaSwitchV3
                        id={'switchIsAgentRet'}
                        name="isAgentRet"
                        status={false}
                        label={"Agente de Retención"}
                        control={control}
                        register={registerLic}
                        disabled={!isPersBill} />
                    <TaTypography text="* Reconversión Monetaria no es un servicio, no aplica retención de ISLR." color="var(--main-text-color0)" />
                </Grid>
            </Grid>
        </form>
    );
});

InfoLicense.propTypes = {

};

export default InfoLicense;