import {
    SNACKBAR_OPEN,
    SNACKBAR_CLOSE,
    SNACKBAR_OPENAPI,
} from "./SnackbarConstants"

const SnackbarReducer = (state, action) => {
    //console.log('SnackbarReducer', state, action)
    switch (action.type) {
        case SNACKBAR_OPEN:
            return {
                ...state,
                isOpen: true,
                variant: action.variant,
                message: action.message,
            }
        case SNACKBAR_CLOSE:
            return {
                ...state,
                isOpen: false,
            }
        case SNACKBAR_OPENAPI:
            return {
                ...state,
                isOpen: (action.data.message) ? (action.data.message !== "") : false,
                variant: apiVariant(action.statusCode),
                message: (action.data.message !== "") ? action.data.message : ""
            }
        default:
            return state
    }
}

export default SnackbarReducer

const apiVariant = (statusCode) => (statusCode === 200 || statusCode === 201) ? 'success' : statusCode === '401' ? 'warning' : 'error'