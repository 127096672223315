import {
    fnPostUrl,
} from "functions/Api";
import { urlRm } from 'services/global'

export const postRegister = (infoLic, infoTc, infoPay, infoTransfer, totalAmount, zelleCode) => {
    try {
        const body = {
            FormType: infoPay.paymentMethod,
            License: infoLic.license,
            LicType: infoLic.licenseType,
            IdClient: infoLic.fiscalID,
            ClientName: infoLic.fiscalName,
            Email: infoLic.email,
            Phone: infoLic.phone ?? '',
            IsPerFact: infoLic.isPersBill,
            IsAgentRet: infoLic.isAgentRet,
            IsValid: true,
            TblBank: (infoPay.paymentMethod === "1")
                ? infoTransfer.map(x => ({ Bank: x.bank, TranDate: x.date, Amount: x.amount, Reference: x.refere, IdClient: x.fiscalID, factor: x.factor }))
                : [],
            CodUser: (infoPay.paymentMethod === "2") ? infoTc.codAliComer : '',
            TotalAmount: totalAmount,
            ZelleCode: zelleCode,
        }
        const apiRequest = {
            endpoint: 'api/Register/Reg2021',
            baseUrl: urlRm,
            body: body
        }

        // console.log(apiRequest)
        return fnPostUrl(apiRequest)

    } catch (error) {
        console.error('postRegister =>', error)
    }
}

export const postRegisterAcc = (infoLicAcc, infoTc, infoPay, infoTransfer, totalAmount, companyQty, zelleCode) => {
    try {
        const body = {
            FormType: infoPay.paymentMethod,
            License: infoLicAcc.license,
            LicType: infoLicAcc.licenseType,
            IdClient: infoLicAcc.fiscalID,
            ClientName: infoLicAcc.fiscalName,
            Email: infoLicAcc.email,
            Phone: infoLicAcc.phone ?? '',
            IsPerFact: infoLicAcc.isPersBill,
            IsAgentRet: infoLicAcc.isAgentRet,
            IsValid: true,
            TblBank: (infoPay.paymentMethod === "1")
                ? infoTransfer.map(x => ({ Bank: x.bank, TranDate: x.date, Amount: x.amount, Reference: x.refere, IdClient: x.fiscalID, factor: x.factor }))
                : [],
            CodUser: (infoPay.paymentMethod === "2") ? infoTc.codAliComer : '',
            TotalAmount: totalAmount,
            QtyBuy: companyQty,
            ZelleCode: zelleCode,
        }
        const apiRequest = {
            endpoint: 'api/Saec/Reg2021',
            baseUrl: urlRm,
            body: body
        }

        // console.log(apiRequest)
        return fnPostUrl(apiRequest)

    } catch (error) {
        console.error('postRegister =>', error)
    }
}