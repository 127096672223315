import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import registerServiceWorker from './registerServiceWorker';
import Home from "./views/home/";
import { SnackbarProvider } from "components/snackbar/SnackbarContext";
import Snackbar from "components/snackbar/Snackbar";

Number.prototype.formatNumb = function (n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

ReactDOM.render(
    <React.Fragment>
        <SnackbarProvider>
            <Home />
            <Snackbar/>
        </SnackbarProvider>
    </React.Fragment>
    , document.getElementById('root'));

registerServiceWorker();
