import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    ListItem,
} from "@material-ui/core/"
import ListStyle from "components/styles/List";
import * as moment from "moment"
import TaFab from "components/fab/TaFab"

const BankDetail = memo(({ data, delBank }) => {
    const { date, refere, fiscalID, amount, bank, id } = data
    const classes = ListStyle();
    return (
        <ListItem classes={{ root: classes.root }}>
            <Grid container alignContent='center' alignItems='center' spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    {`Fecha: ${moment(new Date(date)).format("DD-MM-YYYY")} - Banco: ${bank}`}
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    {`Referencia: ${refere} - Id fiscal: ${fiscalID}`}
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                    {`Monto:`}
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2} align="right">
                    {`${amount.formatNumb(2)}`}
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2} align="center">
                    <TaFab color="red" icon="exit" onClick={() => delBank(id)} />
                </Grid>
            </Grid>
        </ListItem>
    );
});

BankDetail.propTypes = {
    data: PropTypes.object
};

export default BankDetail;