import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TaTypography from '../../../../../components/typography/TaTypography';
const Caracteristics = memo(({ ico, title, text }) => {
    return (
        <Grid container alignContent="center" spacing={1}>
            <Grid item xs={5} sm={5} md={3} lg={3} xl={3} align="center">
                <img src={ico} alt={title} className="icoCaracteristics" />
            </Grid>
            <Grid item xs={7} sm={7} md={9} lg={9} xl={9} align="center">
                <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="left">
                        <TaTypography text={title} fontSize="18pt" align="left" fontFamily="GothamMedium"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="left">
                        <TaTypography text={text} fontSize="14pt" align="left" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

Caracteristics.propTypes = {
    ico: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string
};

export default Caracteristics;