import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Paper,
} from "@material-ui/core/"

import {
    makeStyles,
} from '@material-ui/core/styles'

import TaTypography from "components/typography/TaTypography"
import TaProgress from 'components/TaProgress/TaProgress'


const DialogStyles = makeStyles({
    title: {
        margin: "5px",
        padding: "10px !important",
        backgroundColor: "#666666"
    },
    content: {
        margin: "5px",
        padding: "10px !important",
        overflowX: "hidden !important",
    },
    actions: {
        margin: "5px",
        padding: "10px !important",
    },
})

const TaDialog = memo(({ isOpen, onClose, fullWidth, maxWidth, title, content, actions, loading,
    elevation, isTransparent, showLoader, onEntered }) => {
    const classes = DialogStyles();
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={fullWidth && fullWidth}
            maxWidth={maxWidth}
            PaperProps={
                {
                    elevation: elevation,
                    style: {
                        backgroundColor: "#E6E6E6",
                       
                    }
                }
            }
            onEntered={(onEntered) && onEntered}>
            {title && <DialogTitle className={classes.title}>{<TaTypography isTitle>{title}</TaTypography>}</DialogTitle>}
            {content && <DialogContent className={classes.content}>{content}</DialogContent>}
            {actions && <DialogActions className={classes.actions}>{actions}</DialogActions>}
            {showLoader && <Paper elevation={0} style={{ height: "10px" }}>{loading && <TaProgress type='line' />}</Paper>}
        </Dialog>
    )
})

TaDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    title: PropTypes.any,
    content: PropTypes.any,
    actions: PropTypes.any,
    elevation: PropTypes.number,
    isTransparent: PropTypes.bool,
    showLoader: PropTypes.bool,
};

TaDialog.defaultProps = {
    isOpen: false,
    fullWidth: true,
    maxWidth: "sm",
    elevation: 2,
    isTransparent: false,
    showLoader: true,
};

export default TaDialog