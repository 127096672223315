
import React from 'react'

//CSS
import './TaProgress.css'

//MUI
import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles'
import orange from '@material-ui/core/colors/orange'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import TaTypography from 'components/typography/TaTypography'


const theme = createTheme({
    palette: {
        primary: orange
    },
})

const styles = () => ({
    containerProgressBar: {
        height: '10px'
    },
    containerProgressBarText: {
        height: '15px'
    }
})

const TaProgress = ({ classes, type, text }) => {

    const pickedProgress = (type) => {
        switch (type) {
            case 'TA':
                return <div className="GifP"></div>
            case 'line':
                return (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={(text) ? classes.containerProgressBarText : classes.containerProgressBar}>
                        <MuiThemeProvider theme={theme}>
                            {text ? <TaTypography  align="right">{'Procesando...'}</TaTypography> : ''}
                            <LinearProgress style={{ backgroundColor: 'var(--main-bg-color1)' }} />
                        </MuiThemeProvider>
                    </Grid>

                )
            default:
                return (
                    <MuiThemeProvider theme={theme}>
                        <CircularProgress style={{ marginTop: '5px' }} />
                    </MuiThemeProvider>
                )
        }
    }
    return pickedProgress(type)
}

export default withStyles(styles)(TaProgress)
