import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RHFInput } from "react-hook-form-input";

import {
    MenuItem,
    TextField,
} from "@material-ui/core/"

import useTextField from "helper/UseTextField";
import menuItemStyle from "../styles/MenuItem";

const TaListTextField = memo(({ name, label, register, setValue,
    errors, options, fullWidth, disabled,
    required }) => {
    const txt = useTextField(name, label, errors, 'text');
    const classes = menuItemStyle();
    return (
        <RHFInput
            as={
                <TextField
                    {...txt}
                    select
                    fullWidth={fullWidth}
                    disabled={disabled}>
                    {
                        options.map(option => <MenuItem className={classes.root} key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)
                    }
                </TextField>
            }
            name={txt.name}
            register={register}
            setValue={setValue} 
            rules={{required: required}}/>
    );
});

TaListTextField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    register: PropTypes.any.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    options: PropTypes.array,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

TaListTextField.defaultProps = {
    options: [],
    fullWidth: false,
    disabled: false,
}

export default TaListTextField;