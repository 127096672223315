import { Grid } from '@material-ui/core'
import TaTextField from 'components/textfield/TaTextFieldV4'
import React, { memo } from 'react'
import { regexLicense } from "helper/RegExp"
import TaButton from 'components/button/TaButton'
import TaSwitchV3 from 'components/switch/TaSwitchV3'
import TaTypography from 'components/typography/TaTypography'
import NumberTextField from 'components/textfield/NumberTextField'

const InfoLicenceAcc = memo(({ registerLicAcc, isInfoLic, controlLicAcc,
  handleSubmitLicAcc, fnSubmitRegister, isDisabledField, isPersBill }) => {
  return (
    <form key={'licAcc'} onSubmit={handleSubmitLicAcc(fnSubmitRegister)} >
      <Grid container spacing={2} alignItems={'flex-start'}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TaTextField
            id={'txtLicenceAcc'}
            name={'license'}
            label={'Licencia Saint Enterprise Contable *'}
            placeholder={'ABDC1234567890123456'}
            register={registerLicAcc({ required: 'Requerido', pattern: regexLicense })}
            fullWidth={true} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TaButton label={'Verificar'} type='submit' fontSize={'10pt'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TaTextField
            id={'txtfiscalName'}
            name={'fiscalName'}
            label={'Razón Social'}
            register={registerLicAcc()}
            fullWidth={true}
            disabled={isDisabledField} />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <TaTextField
            id={'txtRif'}
            name={'rif'}
            label={'Identificador Fiscal'}
            register={registerLicAcc()}
            fullWidth={true}
            disabled={isDisabledField} />
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} >
          <TaTextField
            id={'txtType'}
            name={'licenseType'}
            label={'Tipo de licencia'}
            register={registerLicAcc()}
            fullWidth={true}
            disabled={isDisabledField} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TaSwitchV3
            id={'switchIsPersBill'}
            name={'isPersBill'}
            status={false}
            disabled={!isInfoLic}
            label={'Factura personalizada'}
            control={controlLicAcc}
            register={registerLicAcc()}
          />
          <TaTypography text={'* Se enviará a su correo electrónico una plantilla con la información requerida'} color={'var(--main-text-color0)'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TaSwitchV3
            id={'switchIsAgentRet'}
            name={'isAgentRet'}
            status={false}
            disabled={!isPersBill}
            label={'Agente de Retención'}
            control={controlLicAcc}
            register={registerLicAcc()} />
          <TaTypography text={'* Reconversión Monetaria no es un servicio, no aplica retención de ISLR.'} color={'var(--main-text-color0)'} />
        </Grid>
      </Grid>
    </form >
  )
})

export default InfoLicenceAcc