
import {
    makeStyles,
} from '@material-ui/core/styles';

export default makeStyles({
    root: (props) => ({
        height: (props.height) ? props.height : "100%",
        backgroundColor: "var(--main-list-color0)",
        marginTop: "3px",
        marginBottom: "4px",
        padding: "2px",
        paddingLeft: (props.paddingLeft) ? props.paddingLeft : "2px",
        borderRadius: (props.borderRadius) ? "5px" : "0px",
        cursor: props.pointer ? 'pointer' : 'default',
        fontSize: (props.fontSize) ? props.fontSize : "14pt",
        fontFamily: "Archivo",
        color: "var(--main-text-color0)",
        '&:hover': {
            backgroundColor: (props.hover) ? "var(--main-hover-color1) !important" : "var(--main-list-color0)",
            color:  (props.hover) ? "var(--main-text-color1) !important" : "var(--main-text-color0)",
        },
    }),
    selected: {
        backgroundColor: "var(--main-hover-color0) !important",
        color: "var(--main-text-color1) !important",
    },
    rootList: {
        backgroundColor: "var(--main-list-color0)",
    },
    button: {
        backgroundColor: "var(--main-bg-color1)",
        '&.Mui-focusVisible': {
            backgroundColor: "var(--main-bg-color1)",
        },
        '&:hover': {
            backgroundColor: "var(--main-hover-color1) !important",
            //color: "var(--main-text-color0) !important"
        }
    }
});