import { urlRm } from 'services/global'
import {
  fnPostUrl,
} from "functions/Api";

export const postLogin = (user, pass, companyQty) => {
  const apiRequest = {
    endpoint: `api/Login/`,
    baseUrl: urlRm,
    body: {
      User: user,
      Pass: pass,
      QtyBuy: companyQty
    }
  }
  return fnPostUrl(apiRequest)
}