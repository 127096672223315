import React, { memo, useEffect } from 'react';
import {
    Grid,
} from '@material-ui/core'
import TaTypography from 'components/typography/TaTypography';
import TaButton from '../../components/button/TaButton';

const Zelle = memo(({ fnGetZelleCode, fnOnSubmitZelle, zelleCode, isLoading }) => {
    useEffect(() => {
        fnGetZelleCode();
    }, [])
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TaTypography text={"Correo: totalaplicacionespagos@gmail.com"} color="var(--main-text-color0)" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TaTypography text={`Colocar la siguiente referencia para validar el pago:`} align="center" color="var(--main-text-color0)"/>
                <TaTypography text={zelleCode} fontSize="16pt" align="center" color="var(--main-text-color0)"  />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} align="right">
                <TaButton disabled={isLoading || zelleCode === ''} label='Verificar pago' onClick={fnOnSubmitZelle} fontSize="12pt"></TaButton>
            </Grid>
        </Grid>
    );
});

export default Zelle;