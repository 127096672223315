import Grid from '@material-ui/core/Grid';
import logoTa from '../../../../img/logowhite.png';
import logoSaint from '../../../../img/LogoSaintWhite.png';
import logoApp from '../../../../img/app.png';
import Caracteristics from './components/Caracteristics';
import icoAutomatizado from '../../../../img/iconos/Automatizado.png';
import icoSeguro from '../../../../img/iconos/Seguro.png';
import icoRapido from '../../../../img/iconos/Rapido.png';
import icoGarantia from '../../../../img/iconos/Garantia.png';
import TaTypography from '../../../../components/typography/TaTypography';
import "./index.css";
import useWindowSize from "helper/UseWindowsSize";

const Header = () => {
    const {isMobile} = useWindowSize();

    return (
        <Grid container alignContent="center" className={"header"} spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align="center">
                <figure><a href="https://totalaplicaciones.com/" target="_blank"><img src={logoTa} alt="TotalAplicaciones" className={"imgTa2"} /></a></figure>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align="center">
                <figure><a href="https://saintnet.com/" target="_blank"><img src={logoSaint} alt="saint" className={"imgSaint"} /></a></figure>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaTypography text={"Reconversión Monetaria"} fontSize={isMobile ? "24pt" : "48pt"} align="center" fontFamily="GothamMedium" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container alignContent="flex-start">
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align="center"></Grid>
                    <Grid item xs={10} sm={10} md={3} lg={3} xl={3} align="center">
                        <Grid container alignContent="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography text={`A partir del 1 de octubre de 2021 entrará en vigencia el Bolívar Digital por el  Decreto N° 4.553 mediante el cual se decreta 
                                la “Nueva Expresión Monetaria”, publicado en la Gaceta Oficial N° 42.185 del 6 de agosto de 2021; así como la aplicación conjunta de 
                                la Resolución No. 21-08-01 emitida por el Banco Central de Venezuela (BCV), mediante la cual se dictan las “Normas que rigen 
                                La Nueva Expresión Monetaria”, publicado en la Gaceta Oficial N° 42.191 del 16 de agosto de 2021.`} fontSize="14pt" align="justify" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography text={`Al ampliar una escala monetaria que suprime seis (6) ceros en la moneda nacional. Se reexpresa la unidad del sistema 
                                monetario de la República Bolivariana de Venezuela, en el equivalente a un millón bolívares (Bs. 1.000.000) actuales.`} fontSize="14pt" align="justify" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography text={`En consecuencia, todo importe expresado en moneda nacional antes de la citada fecha, deberá ser convertido a la nueva unidad, dividiéndolo entre un millón (1.000.000).`} fontSize="14pt" align="justify" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography text={`Realiza la reconversión monetaria a bolívares digitales a tu sistema Saint Enterprise Administrativo, Saint Enterprise Contable, 
                                y las herramientas de Total Aplicaciones de la manera más segura y sencilla.`} fontSize="14pt" align="justify" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} sm={10} md={4} lg={4} xl={4} align="center"><img src={logoApp} alt="interfaz" className="imgApp" /></Grid>
                    <Grid item xs={10} sm={10} md={3} lg={3} xl={3} align="center">
                        <Grid container alignContent="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Caracteristics title={"Automatizado"} text={"Con un sólo clic, realiza todo el proceso de reconversión"} ico={icoAutomatizado} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Caracteristics title={"Seguro"} text={"Validaciones incluidas que evitan errores en el proceso"} ico={icoSeguro} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Caracteristics title={"Rápido"} text={"Haz todo el proceso en tiempo record"} ico={icoRapido} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Caracteristics title={"Garantia"} text={"100% respaldado por nuestro equipo de soporte. No dude en contactarnos a través de nuestro chat de soporte"} ico={icoGarantia} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align="center"></Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;