import { useEffect, useState } from 'react';
import * as moment from 'moment'
import {
    textfieldStyles,
    textfieldLabelStyles,
} from "components/styles/TextField";

const useTextField = (name, label, errors, type, placeholder = '', maxLength, onKeyDown, type2, textAlign) => {
    const textfieldClasses = textfieldStyles();
    const textfieldLabelClasses = textfieldLabelStyles();
    const [textfield, setTextfield] = useState({
        name: name,
        label: label,
        helperText: errors ? errors[name]?.message ?? "" : "",
        error: errors ? (errors[name]) ?? false : false,
        placeholder: placeholder,
        InputProps: {
            classes: textfieldClasses,
        },
        InputLabelProps: {
            classes: textfieldLabelClasses,
            shrink: true,
        },
        inputProps: {
            maxLength: maxLength ?? 524288, min: type === 'number' ? "0" :
                type === 'date' ? "1900-01-01" : undefined, max: type === 'date' ? moment(new Date()).format("YYYY-MM-DD") :
                    type2 === 'numberZip' ? '9999' : type2 === 'docId' ? '9999999999' : undefined, step: type === 'number' ? "1" : undefined,
            style: { textAlign: (textAlign) ? textAlign : 'left' }
        },
        type: type,
        onKeyDown: onKeyDown ? onKeyDown : undefined,
        autoComplete: 'off'
    });
    useEffect(() => {
        setTextfield(preVal => ({
            ...preVal,
            helperText: errors ? errors[name]?.message ?? "" : "",
            error: errors ? (errors[name]) ?? false : false,
        }))
    }, [errors, placeholder, name]);
    return textfield;
}

export default useTextField;