import {memo} from "react";
import PropTypes from 'prop-types'

//MUI
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const buttonStyles = makeStyles(() => ({
    root: props => ({
        margin: props.topMargin ? '12px' : '5px',
        minWidth: '165px',
        background: '#FF8602',
        color: 'white',
        // '&:hover': {
        //     background: props.color ? props.color : 'var(--main-bg-color3)',
        // },
        borderRadius: "5px",
        fontFamily: "GothamBook",
        fontSize:  props.fontSize ? props.fontSize : '14pt',
    })
}))

const TaButton = memo(({
    id, label, disabled, onClick,
    size, variant, topMargin, index,  
    uniqueKey, color, fontSize, type, href, target,
}) => {
    const fnOnClick = () => (onClick) && onClick(id, uniqueKey)
    const buttonClasses = buttonStyles({ topMargin, color, fontSize })
    return (
        <Button
            variant={variant}
            disabled={disabled}
            onClick={fnOnClick}
            size={size}
            classes={buttonClasses}
            data-id={index}
            type={type ?? type}
            href={href}
            target={target}>
            {label}
        </Button>
    )
})

TaButton.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    size: PropTypes.string,
    loading: PropTypes.bool,
    topMargin: PropTypes.bool,
    setRef: PropTypes.func,
    hidden: PropTypes.bool,
    hotkey: PropTypes.string,
}

TaButton.defaultProps = {
    disabled: false,
    variant: "contained",
    size: "medium",
    loading: false,
    topMargin: true,
    hidden: false,
    hotkey: "",
    onClick: () => {},
}

export default TaButton