export const regexCompanyId = {
    value: /^[A-Z0-9-]{3,25}$/i,
    message: "Id invalido"
}


export const regexDocumentId = {
    value: /^[A-Z0-9-]{1,15}$/i,
    message: "Documento invalido"
}

export const regexCompanyName = {
    value: /^[A-Z0-9 -.,]{3,40}$/i,
    message: "Razon social invalida"
}

export const regexName = {
    value: /^[A-Z ]{2,40}$/i,
    message: "Nombre invalido"
}

export const regexLastName = {
    value: /^[A-Z ]{2,40}$/i,
    message: "Apellido invalido"
}

export const regexEmail = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: "Correo invalido"
}

export const regexPass = {
    value: /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
    message: "Contraseña invalida, debe contener al menos un numero, una letra en mayuscula y 6 caracteres"
}

export const regexSimplePass = {
    value: /^[0-9a-zA-Z]{6,}$/,
    message: "Contraseña invalida"
}

export const regexDate = {
    value: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
    message: "Fecha invalida"
}

export const regexPhone = {
    value: /^(\+\d{1,3}[- ]?)?[0]?\d{10}$/,
    message: "Teléfono invalido",
}

export const regexAddress = {
    value: /^[#.0-9a-zA-Z \s,-]{2,40}$/,
    message: "Dirección invalida",
}

export const regexEmail1 = {
    value: /^[A-Z0-9._%+-]{2,40}$/i,
    message: "Correo usuario invalido"
}

export const regexEmail2 = {
    value: /^[A-Z0-9.-]{2,30}\.[A-Z]{2,4}$/i,
    message: "Correo dominio invalido"
}

export const regexZipCode = {
    value: /^[0-9]{4}$/,
    message: "Código postal invalido",
}

export const regexPayAllPhone = {
    value: /^\d{7}$/,
    message: "Teléfono invalido",
}

export const regexPayAllPhoneTv = {
    value: /^\d{8}$/,
    message: "Teléfono invalido",
}

export const regexRIFValidate = {
    value: /^([VEJPGCvejpgc]{1,2})(-)?([0-9]{3,9})(-)?([0-9]{1}$)/,
    message: "RIF invalido"
}

export const regexRIF = {
    value: /^([0-9]{6,9})(-)?([0-9]{1}$)/,
    message: "RIF invalido"
}

export const regexZNumber = {
    value: /^[0-9]{1,6}$/,
    message: "Número de Z inválido",
}

export const regexIP = {
    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    message: "Direccion IP inválido",
}

export const regexPort = {
    value: /^[0-9]{1,5}$/,
    message: "Puerto inválido",
}

export const fnValidateFirstCharRIF = (string) => {
    return (string.charAt(0) === 'V' || string.charAt(0) === 'E' || string.charAt(0) === 'J' || string.charAt(0) === 'P' || string.charAt(0) === 'G')
}

export const regexLicense = {
    value: /^[A-Z]{4}[0-9]{15}$/,
    message: "Licencia incorrecta",
}