import { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import logoTa from '../../../../img/logo.png';
import "./index.css";
import TaButton from '../../../../components/button/TaButton';
import TaTypography from '../../../../components/typography/TaTypography';
import Register from "../../../register/";
import RegisterAccounting from "views/registerAccounting";
import Menu from "./components/Menu"

const Body = () => {
    const [isOpenRegister, setIsOpenRegister] = useState(false);
    const [isOpenRegisterAcc, setIsOpenRegisterAcc] = useState(false)

    const fnOnOpenRegister = () => setIsOpenRegister(!isOpenRegister)
    const fnOnOpenRegisterAcc = () => setIsOpenRegisterAcc(!isOpenRegisterAcc)

    return (
        <Grid container alignContent="center" spacing={4} className="bodyTa">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid container alignContent="center" alignItems="center">
                    <Grid align='center' item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/3WV78Lp6Lr0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <TaTypography text="Le invitamos a descargar y probar nuestro aplicativo" fontSize="18pt" fontFamily="GothamMedium" color="black" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid container alignContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={false} sm={1} md={1} lg={1} xl={1} align='center'></Grid>
                    <Grid item xs={12} sm={5} md={4} lg={4} xl={4} align='center'>
                        <Menu
                            title={"SAINT ENTERPRISE ADMINISTRATIVO"}
                            fnFirstButton={fnOnOpenRegister}
                            secondTextButton={"Prueba"}
                            secondLinkButton={"https://apps.totalaplicaciones.com/ReconversionMonetaria.msi"}
                            thirdLinkButton={"https://totalcoin.totalaplicaciones.com:8086/documentos/reconversionmonetaria2021.pdf"}
                            fourthLinkButton={"https://youtu.be/kau4UKfhDto"} />
                    </Grid>
                    <Grid item xs={false} sm={false} md={2} lg={2} xl={2} align='center'></Grid>
                    <Grid item xs={12} sm={5} md={4} lg={4} xl={4} align='center'>
                        <Menu
                            title={"SAINT ENTERPRISE CONTABLE"}
                            fnFirstButton={fnOnOpenRegisterAcc}
                            secondTextButton={"Descargar"}
                            secondLinkButton={"https://apps.totalaplicaciones.com/ECReconversionMonetaria.msi"}
                            thirdLinkButton={"https://totalcoin.totalaplicaciones.com:8086/documentos/reconversionmonetariacontable2021.pdf"}
                            fourthLinkButton={"https://youtu.be/2-uRC7NatVE"} />
                    </Grid>
                    <Grid item xs={false} sm={1} md={1} lg={1} xl={1} align='center'></Grid>
                </Grid>
            </Grid>
            <Grid item xs={false} sm={2} md={2} lg={2} xl={2}></Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <TaTypography text="Si eres Cliente Final y requieres apoyo técnico con el proceso de reconversión, contáctanos" fontSize="14pt" color="black" align="center" />
            </Grid>
            <Grid item xs={false} sm={2} md={2} lg={2} xl={2}></Grid>
            <Grid item xs={false} sm={2} md={2} lg={2} xl={2}></Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <TaTypography text="El proceso de reconversión monetaria, reconvierte todos los valores monetarios
                                    de Saint Enterprise Administrativo y cualquier aplicativo del Total Aplicaciones en un solo proceso
                                    y por un solo precio" fontSize="14pt" color="black" align="center" />
            </Grid>
            <Grid item xs={false} sm={2} md={2} lg={2} xl={2}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaTypography text="Conoce nuestro portafolio de aplicaciones" fontSize="18pt" color="black" align="center" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <figure><a href="https://totalaplicaciones.com/" target="_blank"><img src={logoTa} alt="TotalAplicaciones" className="imgTa" /></a></figure>
            </Grid>
            {isOpenRegister && <Register onClose={fnOnOpenRegister} />}
            {isOpenRegisterAcc && <RegisterAccounting onClose={fnOnOpenRegisterAcc} />}
        </Grid>
    );
};

export default Body;