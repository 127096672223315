import { makeStyles } from '@material-ui/core/styles';

export const textfieldStyles = makeStyles({
    input: props => (
        {
            fontFamily: "GothamBook",
            fontSize: "12pt",
            color: 'var(--main-text-color0)',
            // textTransform: 'uppercase',
            // width: props.width ? props.width : '160px'
        }
    ),
    // root: props => (
    //     {
    //         width: props.type === 'select' ? '160px' : props.width ? props.width : undefined
    //     }
    // ),
    underline: {
        '&:hover': {
            borderBottom: '1px solid var(--main-hover-color0)',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid var(--main-hover-color0)',
        },
        '&:before': {
            borderBottom: '2px solid var(--main-bg-color1)',
        },
        '&:after': {
            borderBottom: '1px solid var(--main-hover-color0)',
        }
    },
});

export const textfieldLabelStyles = makeStyles({
    root: {
        fontFamily: "GothamBook",
        fontSize: "14pt",
        color: 'var(--main-text-color0)',
        '&$focused': {
            color: 'var(--main-bg-color1)',
        },
        textAlign:'left'
    },
    focused: {
        color: 'var(--main-bg-color1)',
    }
});